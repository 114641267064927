const ResumePage = () => {
    return <>
        <main id="main">
            <section id="resume" className="resume">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Resume</h2>
                        <p>This portion includes my Educational History, my participations so far on different communities
                            and my
                            working experience</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="resume-title">Sumary</h3>
                            <div className="resume-item pb-0">
                                <h4>Er. Bimal Adhikari</h4>
                                <p><em>Innovative and deadline-driven Developer with experience of developing
                                    Enterprise resource planning (ERP) systems from initial concept to final, polished
                                    deliverable.</em></p>
                                <p>
                                    <ul>
                                        <li>Kathmandu,Nepal</li>
                                        <li>(977) 9860558293</li>
                                        <li>bimaladhikari8158@gmail.com</li>
                                    </ul>
                                </p>
                            </div>

                            <h3 className="resume-title">Education</h3>
                            <div className="resume-item">
                                <h4>Bachelor's Degree in Computer Engineering </h4>
                                <div className="d-flex">
                                    <h5 className="mx-2">2018 - 2023</h5>
                                    <h5>72.07 %</h5>
                                </div>
                                <p><em>National College of Engineering, Institue of Engineering  <br />Tribhuvan University,
                                    Nepal</em></p>
                                <p>This is 4 years course intended to make the students eligible for software development,
                                    network design
                                    and
                                    implementation, hardware and software troubleshooting, network security policies,etc.
                                </p>
                            </div>
                            <div className="resume-item">
                                <h4>Intermediate Degree - Science</h4>
                                <h5 className="mx-2">2015-2017</h5>
                                <h5>74.70 %</h5>
                                <p><em>Liverpool International College  <br />New Baneshwor,Kathmandu,Nepal</em></p>
                                <p>This is 2 years course offering an
                                    integrated package in Physics, Chemistry, Biology and Mathematics.
                                    The program aims to equip students with a level fit for further studies in technology,
                                    medicine, engineering and biotechnology. Plus two in Science is for those students who
                                    have an aptitude for science and mathematics. This program aims to develop strong
                                    foundation in
                                    students. On completion students can pursue their further studies in medicine,
                                    engineering or other
                                    science related studies.</p>
                            </div>
                            <div className="resume-item">
                                <h4>Schooling</h4>
                                <h5 className="mx-2">2003-2015</h5>
                                <h5>82.00 %</h5>
                                <p><em>MoonLight Academy  <br />Chandranigahpur,Rautahat,Nepal</em></p>
                                <p>This course is divided into pre-primary level,primary level and secondary level providing
                                    basic
                                    education foundation.
                                </p>
                            </div>
                            <h3 className="resume-title">Licenses & Certifications</h3>
                            <div className="resume-item pb-0">
                                <h4>Engineering License</h4>
                                <p><em>License for professionally carrying out computer engineering related works.</em></p>
                            </div>
                            <div className="resume-item pb-0">
                                <h4>React JS Course Completion Certificate</h4>
                                <p><em>Certificate for completion of React JS  <br />
                                    Broadway Infosys, Tinkune,Kathmandu
                                </em>
                                    <br />
                                    <a className="certification-click"
                                        href="https://verify.broadwayinfosys.com/certificate-verification-code/eyJpdiI6IlhVWWJQQ25xMWVnRFwveVN6Y095TFRnPT0iLCJ2YWx1ZSI6InlZWVhoMzY2MGo1U2FwdjJ6V1J2V3c9PSIsIm1hYyI6ImI2ZDM0N2JiMjZiOTg3Y2I1YTg5NGY1YWJlZGQwNTc0YTQyMDFiNWZiYzAxNWMyYjgwYTFjYjZmNWU2NWZiODcifQ==">
                                        Click to view
                                    </a>
                                </p>
                            </div>
                            <div className="resume-item pb-0">
                                <h4>edX Verified Certificate for CS50's Web Programming with Python and JavaScript</h4>
                                <a className="certification-click"
                                    href="https://courses.edx.org/certificates/317e33b48da349b499b447eb5f8e4e03">
                                    Click to view
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <h3 className="resume-title">Professional Experience</h3>
                            <div className="resume-item">
                                <h4>PHP/Laravel Developer</h4>
                                <p><em>Himalayan Techies Pvt. Ltd. <br />Anamnagar,Kathmandu</em></p>
                                <h5>June 2022 - Present</h5>
                                <p>
                                    <ul>
                                        <li>Design and implement web applications that use the Laravel framework</li>
                                        <li>Develop REST API for frontend to interact with.</li>
                                        <li>Work in a team to develop Enterprise resource planning (ERP) systems for Top
                                            Companies of Nepal.
                                        </li>
                                        <li>Designing and Interact with SQL databases making tables, relationships, pivot
                                            tables,common table
                                            expression(CTE) and stored procedures.</li>
                                    </ul>
                                </p>
                            </div>
                            <h3 className="resume-title">Participations</h3>
                            <div className="resume-item">
                                <h4>President</h4>
                                <p><em>National College of Engineering, IT Club</em></p>
                                <h5>January 2020 - December 2021</h5>
                                <p>
                                    <ul>
                                        <li>Coordinate all the activities and events conducted by club.</li>
                                        <li>Recruiting new members and providing trainings for newly recruited members.</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="resume-item">
                                <h4>General Member</h4>
                                <p><em>National College of Engineering, IT Club</em></p>
                                <h5>October 2018 - January 2020</h5>
                                <p>
                                    <ul>
                                        <li>Organization of serveral events in Colleges.</li>
                                        <li>Participate on events conducted by other Colleges.</li>
                                    </ul>
                                </p>
                            </div>
                            <hr />
                            <div className="resume-item">
                                <h4>Vice Project Lead</h4>
                                <p><em>Code for Change, Kathmandu</em></p>
                                <h5>January 2022 - December 2022</h5>
                                <p>
                                    <ul>
                                        <li>Coordinate all the activities and events conducted by the organization.</li>
                                        <li>Recruiting new members and providing trainings for newly recruited members.</li>
                                        <li>Search for Partnership for organization of events.</li>
                                        <li>Help in proper utilization of funds.</li>
                                        <li>Organization of several trainings, webinars, seminars, social impact
                                            events,hackethon,etc.</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="resume-item">
                                <h4>College Representative</h4>
                                <p><em>Code for Change, Kathmandu</em></p>
                                <h5>January 2021 - December 2021</h5>
                                <p>
                                    <ul>
                                        <li>Participate on several events conducted by organization.</li>
                                        <li>Coordinate with executives and handling general members of the organization.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    </>;

};
export default ResumePage;