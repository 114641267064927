import PortfolioContainer from './portfolioContainer';


const PortfolioPage = () => {
    return <>
        <section id="portfolio" className="portfolio mt-5">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Portfolio</h2>
                    <p>This includes my works that are public to everyone.</p>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li data-filter="*" className="filter-active">All</li>
                            <li data-filter=".filter-web">Web</li>
                            <li data-filter=".filter-app">App</li>
                        </ul>
                    </div>
                </div>
                <PortfolioContainer />
            </div>
        </section>

    </>;

};
export default PortfolioPage;