import { Outlet } from "react-router";
import Footer from "./footer";
import Header from "./header";

const Layout = () => {

    return (<>
        <Header></Header>
        <Outlet />
        <Footer></Footer>
    </>);
}

export default Layout;