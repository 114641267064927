import React from 'react';
import SkillProgressBar from './SkillProgressBar'; // Update the path accordingly

const Skills = () => {
    return (
        <>
            <section id="skills" className="skills">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Technical Skills</h2>
                        <p>These are the skills that I have been familiar with and continuously working for its development.</p>
                    </div>

                    <div className="row skills-content pb-5">
                        <div className="col-lg-6">
                            <SkillProgressBar skillName="HTML" progress={80} />
                            <SkillProgressBar skillName="CSS" progress={70} />
                            <SkillProgressBar skillName="JS" progress={80} />
                            <SkillProgressBar skillName="React JS" progress={50} />
                            <SkillProgressBar skillName="Jquery" progress={50} />
                            <SkillProgressBar skillName="Photoshop" progress={55} />
                        </div>

                        <div className="col-lg-6">
                            <SkillProgressBar skillName="PHP" progress={60} />
                            <SkillProgressBar skillName="Laravel" progress={70} />
                            <SkillProgressBar skillName="MY SQL" progress={70} />
                            <SkillProgressBar skillName="Postgres SQL" progress={60} />
                            <SkillProgressBar skillName="Python/Django" progress={50} />
                            <SkillProgressBar skillName="Machine Learning" progress={30} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Skills;
