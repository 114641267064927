import profile_picture from "./about.jpg";
const GeneralIntro = () => {
    return <>
        <section id="about" className="about mt-5">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>About</h2>
                    <p>Quick learner with a positive learning attitude looking for opportunities to work with full
                        potential;
                        interested to develop a career through new challenges.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <img src={profile_picture} className="img-fluid" id="about-page-my-image" alt="" />
                    </div>
                    <div className="col-lg-8 pt-4 pt-lg-0 content d-flex flex-column">
                        <h3>Bimal Adhikari</h3>
                        <h6 className="fw-bold">Full Stack Developer</h6>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-rounded-right"></i> <strong>Phone:</strong> +977 9860558293</li>
                                    <li><i className="bi bi-rounded-right"></i> <strong>City:</strong> Kathmandu</li>
                                    <li><i className="bi bi-rounded-right"></i> <strong>Age:</strong><span id="age"></span>
                                    </li>
                                    <li><i className="bi bi-rounded-right"></i> <strong>Degree:</strong> Bachelor's in
                                        Engineering</li>
                                    <li><i className="bi bi-rounded-right"></i> <strong>Email:</strong>
                                        bimaladhikari8158@gmail.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>;
};

export default GeneralIntro;