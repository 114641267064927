const Footer = () => {
    return (<>
        <footer id="footer">
            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Bimal</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    </>);
}
export default Footer;