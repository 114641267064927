import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const SkillProgressBar = ({ skillName, progress }) => {
    return (
        <>
            <div className="progress">
                <span className="skill">{`${skillName}`} <i className="val">{`${progress} %`}</i></span>
                <div className="progress-bar-wrap">
                    <ProgressBar now={progress} />

                </div>
            </div>
        </>
    );
};
export default SkillProgressBar;
