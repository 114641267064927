import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const IndexPage = () => {

    return <>
        <section id="hero" className="d-flex align-items-center">
            <div className="container d-flex flex-column align-items-center" data-aos="zoom-in" data-aos-delay="100">
                <h1 className="text-white">Bimal Adhikari</h1>
                <h2 className="text-white">Full Stack Developer</h2>
                <Nav.Link as={Link} to="/about" className='btn-about'>About</Nav.Link>
            </div>
        </section>
    </>
};

export default IndexPage;