import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../layouts/layout";
import IndexPage from "../pages/index";
import PortfolioPage from "../pages/portfolio/portfolio";
import AboutPage from "../pages/about/about";
import ResumePage from "../pages/resume/resume";

const Routing = () => {
    return (<>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<IndexPage />} />
                    <Route path="/portfolio" element={<PortfolioPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/resume" element={<ResumePage />} />
                    <Route path="/*" element={<IndexPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </>);
}
export default Routing;