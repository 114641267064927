import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <>
            <header id="header" className="fixed-top">
                <Navbar expand="lg" id="navbar" className="navbar navbar-dark order-last order-lg-0" data-bs-theme="dark">
                    <Container className='d-flex justify-content-between align-items-center'>
                        <Navbar.Brand as={Link} to="/" className='logo mt-2'>Bimal</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto ms-auto">
                                <Nav.Link as={Link} to="/" className='mx-2 mt-2'>Home</Nav.Link>
                                <Nav.Link as={Link} to="/about" className='mx-2 mt-2'>About</Nav.Link>
                                <Nav.Link as={Link} to="/resume" className='mx-2 mt-2'>Resume</Nav.Link>
                                <Nav.Link as={Link} to="/portfolio" className='mx-2 mt-2'>Portfolio</Nav.Link>
                            </Nav>
                            <div className="d-flex header-social-links mt-2">
                                <a href="https://www.facebook.com/bimal0212" className="facebook">
                                    <i className="bi bi-facebook"></i>
                                </a>
                                <a href="https://www.instagram.com/itssbimal/" className="instagram">
                                    <i className="bi bi-instagram"></i>
                                </a>
                                <a href="https://www.linkedin.com/in/bimal-adh/" className="linkedin">
                                    <i className="bi bi-linkedin"></i>
                                </a>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
};

export default Header;
