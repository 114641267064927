import GeneralIntro from "./generalIntro";
import Skills from "./skills";

const AboutPage = () => {

    return (
        <>
            <GeneralIntro></GeneralIntro>
            <Skills />
        </>
    );
}
export default AboutPage;