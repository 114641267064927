import React, { useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../layouts/components/closeicon';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const PortfolioItem = ({ ...data }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap" onClick={openModal}>
                <img src={data.imageSrc} className="img-fluid" alt="" />
                <div className="portfolio-info">
                    <h4>{data.title}</h4>
                    <p>{data.stack}</p>
                    <div className="portfolio-links">
                        <button
                            className="btn text-white portfolio-details-lightbox"
                            data-glightbox="type: external"
                            title="Portfolio Details"
                            onClick={openModal}
                        >
                            <i className="bx bx-show"></i>
                        </button>
                    </div>
                </div>
            </div>

            <Modal style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1000,
                    backgroundColor: 'rgba(255, 255, 255, 0.75)'
                }
            }}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Portfolio Details"
            >
                <div className="d-flex justify-content-end">
                    <button className="modal-close-btn btn text-dark" onClick={closeModal}>
                        <CloseIcon />
                    </button>
                </div>
                <section id="portfolio-details" className="portfolio-details">
                    <main id="main">
                        {/* ======= Portfolio Details Section ======= */}
                        <section id="portfolio-details" className="portfolio-details">
                            <div className="container">
                                <div className="row gy-4">
                                    <div className="col-lg-8">
                                        <div className="portfolio-details-slider swiper">
                                            <Swiper
                                                // install Swiper modules
                                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                spaceBetween={50}
                                                slidesPerView={1}
                                                navigation
                                                pagination={{ clickable: true }}
                                                scrollbar={{ draggable: true }}
                                            >
                                                {data.imageSrc.map((image, index) => (
                                                    <SwiperSlide>
                                                        <img src={image} alt="" />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="portfolio-info">
                                            {/* Project Information */}
                                            <h3>Project information</h3>
                                            <ul>
                                                <li>
                                                    <strong>Category</strong>: {data.category}
                                                </li>
                                                <li>
                                                    <strong>Stack</strong>: {data.stack}
                                                </li>
                                                <li>
                                                    <strong>Project Github URL:</strong>
                                                    {
                                                        data.githubUrl
                                                            ? data.githubUrl.map((link) => (
                                                                <a className='mx-1' href={link.link}>{link.text}</a>
                                                            ))
                                                            : ''
                                                    }
                                                </li>
                                                {
                                                    data.projectPublicUrl
                                                        ?
                                                        <li>
                                                            <strong>Project Public URL:</strong>{' '}
                                                            <a href={data.projectPublicUrl.link}>{data.projectPublicUrl.text}</a>
                                                        </li>
                                                        : ''
                                                }
                                                {
                                                    data.youtubeUrl
                                                        ?
                                                        <li>
                                                            <strong>Project Youtube URL:</strong>{' '}
                                                            <a href={data.youtubeUrl.link}>{data.youtubeUrl.text}</a>
                                                        </li>
                                                        : ''
                                                }
                                            </ul>
                                        </div>
                                        <div className="portfolio-description">
                                            {/* Project Description */}
                                            <h2>{data.titled}</h2>
                                            <p>{data.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* End Portfolio Details Section */}
                    </main>
                </section>
            </Modal >
        </div >
    );
};

export default PortfolioItem;
