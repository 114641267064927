import React from 'react';
import PortfolioItem from './portfolioItem'; // Update the path accordingly
import cryptoPortfolio from './portfolio/cryptoPortfolio.jpg';
import eBidding from './portfolio/eBidding.jpg';
import attendanceManagement from './portfolio/attendanceManagement.jpg';
import gmailClone from './portfolio/gmailClone.jpg';
import signLanguage from './portfolio/signLanguage.jpg';

const portfolioData = [
    {
        title: 'Crypto Portfolio Management',
        category: 'Web design',
        stack: 'Laravel, JS',
        description: `A general portfolio for managing crypto currency. This allows you create your own
        portfolio where you can store all your
        crypto transactions. It fetches the current price and calculates your returns and
        several other statistics. It contains around 18k+ virtual currency that gets updated
        every hour. The prices are fetched live.`,

        imageSrc: [cryptoPortfolio],
        projectPublicUrl: {
            text: 'https://noprice.co/',
            link: 'https://noprice.co/'
        }

    },
    {
        title: 'E-bidding Website',
        category: 'Web design',
        stack: 'Django',
        description: `A website is created for bidding the items on the web. User can place their items for
        bidding. Other user bids the price and whenever the user wants to close the bid he/she
        can close it and the items goes into the dashboard of winning person.
        User can place the comments on the bids too.`,
        imageSrc: [eBidding],
        githubUrl: [{
            text: 'E-bidding',
            link: 'https://github.com/bimal-tech/project-2-commerce'
        }],
        youtubeUrl: {
            text: 'E-bidding',
            link: 'https://youtu.be/goiruQWDfS4'
        }
    },
    {
        title: 'Attendance Management System',
        category: 'Web design',
        stack: 'Laravel, React JS',
        description: `An attendance Management system that has backend developed on Laravel that gives necessary
        API for React Framework to intereact with. A general React Project is done that records
        the attendace of users. A admin dashboard is created for managing the users and the
        attendance.`,
        imageSrc: [attendanceManagement],
        githubUrl: [
            {
                text: 'Laravel',
                link: 'https://github.com/bimal-tech/User-management'
            },
            {
                text: 'React JS',
                link: 'https://github.com/bimal-tech/Attendance-Management-React'
            }
        ],

    },
    {
        title: 'Gmail Clone',
        category: 'Web design',
        stack: 'Django',
        description: `Trying to attain the mailing feature og Gmail, the website is created using Django and
        JS that allows user to send the email to the users. There are sections for inbox, sent
        and archive too where user can interact with.`,
        imageSrc: [gmailClone],
        githubUrl: [{
            text: 'Gmail Clone',
            link: 'https://github.com/bimal-tech/Mail'
        }],
        youtubeUrl: {
            text: 'Gmail Clone',
            link: 'https://youtu.be/btdG1eV8LcU'
        }

    },
    {
        title: 'Sign Language Recognition System',
        category: 'Web design',
        stack: 'Python, OpenCV, Mediapipe, Tensorflow',
        description: `This is a sample program that recognizes hand signs and finger gestures with a simple MLP using the detected key points.`,
        imageSrc: [signLanguage],
        githubUrl: [{
            text: 'Sign Language',
            link: 'https://github.com/bimal-tech/AmericanSignLanguage'
        }],
        youtubeUrl: {
            text: 'Sign Language',
            link: 'https://youtu.be/UR0TmihRfRA'
        }

    },
];

const PortfolioContainer = () => {
    return (
        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
            {portfolioData.map((data, index) => (
                <PortfolioItem key={'portfolioitem' + index} {...data} />
            ))}
        </div>
    );
};

export default PortfolioContainer;
